<template>
  <div class="units-widget widget bg-white" style="position: relative">
    <div class="accordion" role="tablist">
      <div class="widget-header border-bottom">
        <div class="m-0 h4 d-flex" v-b-toggle="collapsible ? `main-accordion-${id}` : ''">
          <p class="mb-0 mx-auto text-center">{{ title }}</p>
          <template v-if="collapsible">
            <feather-icon
              v-if="!isConfigModeOn"
              :icon="isCollapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              :style="{ opacity: !searchBarEnabled ? 1 : 0 }"
              size="24"
            />
          </template>
          <span
            class="handle cursor-move"
            v-if="isConfigModeOn"
            :style="{ opacity: !searchBarEnabled ? 1 : 0 }"
          >
            <feather-icon icon="MenuIcon" size="24" />
          </span>
        </div>
        <!-- ============== Search bar ============== -->
        <div
          :class="['search-bar', { expanded: searchBarEnabled }]"
          v-show="!loadingUnitsStatus"
        >
          <div
            ref="searchBarContainer"
            class="search-bar-container d-flex align-items-center"
          >
            <feather-icon
              icon="SearchIcon"
              class="mx-25 cursor-pointer"
              size="24"
              @click="toggleSearchBar"
            />
            <input
              type="text"
              v-model="searchQuery"
              class="form-control"
              :placeholder="$t('grid.widgets.units.search.placeholder')"
              @input="onTypeSearch"
            />
            <span class="results-count" v-show="!sidebarMinWidthReached">
              {{ $t("grid.widgets.units.search.results") }}: {{ totalUnits }}
            </span>
            <feather-icon
              icon="XIcon"
              class="mx-25 cursor-pointer"
              size="18"
              @click="toggleSearchBar"
            />
          </div>
        </div>
        <!-- ======================================== -->
      </div>
      <b-collapse
        :id="`main-accordion-${id}`"
        :accordion="`main-accordion-${id}`"
        role="tabpanel"
        visible
        v-model="isCollapseOpen"
        @input="onUpdateStatus"
      >
        <div class="accordion" role="tablist" v-if="tenants.length">
          <b-card
            no-body
            class="mb-0 rounded-0 shadow-none"
            v-for="(tenant, index) in tenants"
            :key="index"
          >
            <b-card-header header-tag="header" class="p-0" role="tab">
              <div class="w-100 mb-0 text-center" v-b-toggle="`accordion-${index}`">
                <div class="m-0 p-25 h4 d-flex">
                  <p class="h5 mb-0 mx-auto p-25">
                    <span>{{ tenant.name }}</span>

                    <span v-if="!loadingUnitsStatus">
                      ({{
                        tenants[index].units_count || 0
                      }})
                    </span>
                    <span v-else> (...) </span>
                  </p>
                  <feather-icon
                    :icon="tenants[index].shown ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    size="24"
                  />
                </div>
              </div>
            </b-card-header>
            <b-collapse
              :id="`accordion-${index}`"
              accordion="tenants-widget"
              role="tabpanel"
              v-model="tenants[index].shown"
              @shown="updateCollabseStatus('shown', tenants[index])"
              @hidden="updateCollabseStatus('hidden', tenants[index])"
            >
              <units-widget
                :id-tenant="tenant.id"
                :filter="unitsResults"
                :total-units.sync="totalUnits"
                :tree-mode="TreeMode"
                :role="role"
                :parent-opened="isCollapseOpen"
                :key="`units-${tenant.name}-${tenant.id}`"
                v-if="tenants[index].shown"
              />
            </b-collapse>
          </b-card>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import {
  BCollapse,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  VBToggle,
} from "bootstrap-vue";
import { debounce } from "lodash";
import store from "@/store";
import UnitsWidget from "./UnitsWidget";

export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    UnitsWidget,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    id: {
      required: false,
      type: Number | String,
    },
    location: {
      type: String,
      default: "bottomBar",
    },
    title: {
      type: String | Number,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    TreeMode: {
      type: String,
      default: "widget",
    },
    role: {
      type: String,
      default: "disabled",
    },
  },
  data() {
    return {
      isCollapseOpen: true,
      searchQuery: "",
      searchBarEnabled: false,
      results: [],
      tenantsResults: [],
      unitsResults: [],
      totalUnits: 0,
    };
  },
  mounted() {
    if (!store.state.grid.units.length) {
      store.dispatch("grid/getUnitsByTenant");
    }
    this.isCollapseOpen = this.isOpen;
    this.$refs.searchBarContainer.style.width = `${this.$el.offsetWidth - 20}px`;
    store.dispatch("grid/loadTreeConfig");
  },
  methods: {
    onUpdateStatus(value) {
      this.$emit("widget-collapsed", {
        id: this.id,
        property: "isOpen",
        location: this.location,
        value,
      });
    },
    updateCollabseStatus(status, tenant) {
      if (status === "hidden") {
        store.dispatch("grid/deleteTree", tenant.id);
      } else {
        const treeConfig = store.getters["grid/getTreeById"](tenant.id);

        if (!treeConfig) {
          const payload = {
            tenantId: tenant.id,
            open: true,
            children: [],
          };
          store.dispatch("grid/addTree", payload);
        } else {
          const payload = {
            tenantId: tenant.id,
            open: true,
            entity: "tenant",
          };
          store.dispatch("grid/updateTree", payload);
        }
      }
    },
    countTenantUnits(tenantId) {
      const tenatUnits = this.units.filter((u) => u.id_tenant === tenantId);
      return tenatUnits ? tenatUnits.length : 0;
    },
    toggleSearchBar() {
      if (!this.searchBarEnabled) {
        this.$refs.searchBarContainer.querySelector("input").focus();
        this.searchBarEnabled = true;
      } else {
        this.searchBarEnabled = false;
        this.resetSearchBar();
      }
      this.isCollapseOpen = true;
    },
    resetSearchBar() {
      this.searchQuery = "";
      this.results = [];
      this.unitsResults = [];
      this.tenantsResults = [];
      store.dispatch("grid/unfilterUnits");
    },
    onTypeSearch: debounce(function () {
      if (this.searchQuery === "") {
        this.resetSearchBar();
        return;
      }

      if (this.searchQuery.length < 3) return;

      const search = this.searchQuery.toLowerCase();

      store.dispatch("grid/filterUnits", {
        search
      });
    }, 800),
  },
  computed: {
    tenants() {
      return store.getters["grid/getTenants"];
    },
    allTenants() {
      return store.getters["grid/getTenants"];
    },
    units() {
      return []
      // return store.getters["grid/getUnits"];
    },
    isConfigModeOn() {
      return store.state.psimConfig.configModeStatus;
    },
    sidebarMinWidthReached() {
      return store.getters["grid/sidebarMinWidthReached"];
    },
    sidebarWidth() {
      return store.state.grid.sidebarWidth;
    },
    loadingUnitsStatus() {
      return store.state.grid.loadingUnits;
    },
    isSearching() {
      return this.searchQuery !== "";
    },
    treeConfig() {
      const config = store.getters["grid/getAllTrees"];
      return config
        ? config.reduce((acc, item) => {
            acc[item.tenantId] = item.open;
            return acc;
          }, {})
        : null;
    },
  },
  watch: {
    sidebarWidth() {
      this.$refs.searchBarContainer.style.width = `${this.$el.offsetWidth - 20}px`;
    },
    allTenants(value) {
      value.forEach((tenant) => {
        if (this.treeConfig[tenant.id]) {
          tenant.shown = this.treeConfig[tenant.id];
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.units-widget .table.table-sm td {
  padding: 0 0.5rem !important;
  max-height: 70px;
}
</style>
