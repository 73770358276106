<template>
  <div>
    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <b-card-header class="pb-50">
        <h5 class="text-capitalize">
          <span v-if="!isUpdating">{{ $t("unit_groups.action_add") }}</span>
          <span v-else>{{ $t("unit_groups.action_update") }}</span>
        </h5>
      </b-card-header>
      <b-card-body>
        <div class="mx-2 mb-2">
          <validation-observer ref="refFormObserver">
            <div class="m-2">
              <!-- ===================================== -->
              <b-row class="mb-2">
                <b-col md="6" class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('unit_groups.fields.name.label')"
                    rules="required"
                  >
                    <label class="d-block">{{
                      $t("unit_groups.fields.name.label")
                    }}</label>
                    <b-form-input
                      v-model="unitGroupName"
                      class="d-inline-block mr-1"
                      :placeholder="$t('unit_groups.fields.name.placeholder')"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </b-row>

              <!-- ===================================== -->
              <b-row>
                <b-col md="12" class="mb-3 text-center">
                  <hr class="mb-1" />
                  <h3>{{ $t("unit_groups.title_main") }}</h3>
                </b-col>
                <b-col md="12">
                  <b-row class="justify-content-center">
                    <b-col md="4" class="unit-groups-source">
                      <tenants-widget
                        :id="1"
                        :collapsible="true"
                        :isOpen="true"
                        tree-mode="editor"
                        role="source"
                      />
                    </b-col>

                    <b-col md="4">
                      <b-row class="mb-1">
                        <b-col md="12">
                          <label class="d-block">{{ $t('unit_groups.fields.group_name.label') }}</label>
                          <b-form inline @submit.prevent="() => null">
                            <b-form-input
                              v-model="groupName"
                              class="d-inline-block mr-1"
                              :placeholder="$t('unit_groups.fields.group_name.placeholder')"
                              size="sm"
                            />
                            <button class="btn btn-primary btn-sm text-capitalize" @click="addNewGroup">
                              {{ $t('unit_groups.btns.add') }}
                            </button>
                          </b-form>
                          <div :class="['drop-area mt-2', { 'drag-over': isDragEnter }]">
                            <unit-group
                              v-if="unitGroupData"
                              ref="tree"
                              :data="unitGroupData"
                              mode="editor"
                              role="destination"
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </validation-observer>
          <b-row>
            <b-col md="12" class="d-flex justify-content-end">
              <b-button variant="success" @click="save" class="text-capitalize">
                {{ $t("actions.save") }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import useUnitGroupsConfig from "./useUnitGroupsConfig";
import TenantsWidget from "../components/GridView/widgets/TenantsWidget.vue";
import UnitGroup from "@/components/UnitGroup";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    TenantsWidget,
    UnitGroup,
  },
  setup(_, context) {
    return { ...useUnitGroupsConfig() };
  },
};
</script>

<style lang="scss" scoped>
.drop-area {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  // max-height: 500px;
  border: 2px dashed rgb(161, 160, 160);
  &.drag-over {
    border: 2px dashed rgb(0, 174, 248);
  }
  p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
  }
}
.unit-groups-source {
  max-height: 580px;
  overflow: auto;
}
</style>
