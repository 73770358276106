import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import useLayoutSlots from '@/composables/layoutSlots';
import store from "@/store";
import { EventBus } from "@/libs/event-bus";
import axios from '@axios';

export default function useActuators() {

  const { setSlotData, removeReactivity } = useLayoutSlots();
  const { t } = useI18nUtils();
  const trans = {
    'grid.widgets.units.subsystems_tree.voip.make_call': t('grid.widgets.units.subsystems_tree.voip.make_call'),
    'grid.widgets.units.subsystems_tree.info': t('grid.widgets.units.subsystems_tree.info'),
  };

  const setChildrenData = (item, actions) => {

    const devices = item.data;

    if (devices.length > 0) {

      devices.forEach((device) => {
        let child_device;

        const rootData = device.subsystem_tree_data.root_data;
        const children = [];
        device.subsystem_tree_data.actions.forEach(item => children.push(item));

        child_device = {
          id: device.device_id + "_root",
          text: rootData.text,
          icon: rootData.icon,
          tag: "actuators",
          visible: true,
          children,
        };

        item.addChild(child_device);
      });
    }
  }

  const actions = async (item, vm, isDnd = false) => {

    switch (item.tag) {

      case "info-actuator":
        setSlotData(
          SLOT_TYPES.INFO,
          item.data.id,
          { data: removeReactivity(item.data) },
          SOURCES.ACTUATOR
        );
        break;

      case "density-test-shoot":
        if (isDnd) return;
        if (confirm('¿Segur@ de ejecutar el disparo de prueba?')) {
          try {
            const data = item.data;
            await axios.get(data.url);
            alert('Commando ejecutado');
          } catch (error) {
            console.log('[run command from subsystem tree]', error);
            alert('Ocurrió un error al ejecutar el comando');
          }
        }
        break;

      case "density-shoot":
        if (isDnd) return;
        if (confirm('¿Segur@ de ejecutar el disparo real?')) {
          try {
            const data = item.data;
            await axios.get(data.url);
            alert('Commando ejecutado');
          } catch (error) {
            console.log('[run command from subsystem tree]', error);
            alert('Ocurrió un error al ejecutar el comando');
          }
        }
        break;
    };
  };

  return { setChildrenData, actions };
}
