import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import useLayoutSlots from '@/composables/layoutSlots';
import store from "@/store";
import { EventBus } from "@/libs/event-bus";
import axios from '@axios';

export default function useGenericDevices() {

  const { setSlotData, removeReactivity } = useLayoutSlots();
  const { t } = useI18nUtils();

  const setChildrenData = (item, actions) => {

    const devices = item.data;

    if (devices.length > 0) {

      devices.forEach((device) => {
        let child_device;

        const rootData = device.subsystem_tree_data.root_data;
        const children = [];
        device.subsystem_tree_data.actions.forEach(item => children.push(item));

        child_device = {
          id: device.device_id + "_root",
          text: rootData.text,
          icon: rootData.icon,
          tag: "generic_devices",
          visible: true,
          children,
        };

        item.addChild(child_device);
      });
    }
  }

  const actions = async (item, vm, isDnd = false) => {

    switch (item.tag) {

      case "info-generic-device":
        setSlotData(
          SLOT_TYPES.INFO,
          item.data.id,
          { data: removeReactivity(item.data) },
          SOURCES.GENERIC_DEVICE
        );
        break;

      case "generic-device":
        if (isDnd) return;
        if (confirm(`¿Segur@ de ejecutar ${item.data.name}?`)) {
          try {
            const data = item.data;
            // await axios.get(data.url);
            // alert('Commando ejecutado');
            console.log(item.data);
          } catch (error) {
            console.log('[run command from subsystem tree]', error);
            alert('Ocurrió un error al ejecutar el comando');
          }
        }
        break;

      case "camera-generic-device":
        setSlotData(
          SLOT_TYPES.CAM_LIVE,
          item.data.name,
          { camera: item.data, region: " - " },
          SOURCES.GENERIC_DEVICE
        );
        break;
    };
  };

  return { setChildrenData, actions };
}
