import { computed } from "@vue/composition-api";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import useLayoutSlots from "@/composables/layoutSlots";
import ability from "@/libs/acl/ability";
import store from "@/store";
import axios from "@axios";

export default function useVideo(unit) {
  const { setSlotData, removeReactivity } = useLayoutSlots();
  const { t } = useI18nUtils();
  const user = computed(() => store.getters["user/getUser"]);
  const trans = {
    "grid.widgets.units.subsystems_tree.video.live_video": t(
      "grid.widgets.units.subsystems_tree.video.live_video"
    ),
    "grid.widgets.units.subsystems_tree.video.recorded_video": t(
      "grid.widgets.units.subsystems_tree.video.recorded_video"
    ),
    "grid.widgets.units.subsystems_tree.video.timeline": t(
      "grid.widgets.units.subsystems_tree.video.timeline"
    ),
    "grid.widgets.units.subsystems_tree.info": t(
      "grid.widgets.units.subsystems_tree.info"
    ),
    "grid.widgets.units.subsystems_tree.disable_digital_output": t(
      "grid.widgets.units.subsystems_tree.disable_digital_output"
    ),
    "grid.widgets.units.subsystems_tree.activate_digital_output": t(
      "grid.widgets.units.subsystems_tree.activate_digital_output"
    ),
  };

  // set children node data
  const setChildrenData = (item) => {
    const cameras = item.cameras;
    const unit_data = {
      id: unit.id,
      description: unit.description,
      unit_planes: unit.unit_planes,
      region: unit.region,
      shared_tenant_permissions: unit.shared_tenant_permissions,
    };

    if (cameras.length > 0) {
      cameras.forEach((cam) => {
        const children = [];

        children.push({
          id: `cam_${cam.id}_live`,
          type: "action",
          tag: "live-video",
          text: trans["grid.widgets.units.subsystems_tree.video.live_video"],
          icon: `feather ${resolveCameraServiceStatus(cam, "live_video")}`,
          data: cam,
          unit_data,
          visible: validatePermissions("Video:live_video", unit_data),
        });

        // TODO: end dahua integration

        children.push({
          id: `cam_${cam.id}_recorded`,
          type: "action",
          tag: "recorded-video",
          text: trans["grid.widgets.units.subsystems_tree.video.recorded_video"],
          icon: `feather ${resolveCameraServiceStatus(cam, "recorded_video")}`,
          data: cam,
          unit_data,
          visible: validatePermissions('Video:record_video', unit_data),
        });

        children.push({
          id: `cam_${cam.id}_timeline`,
          type: "action",
          tag: "timeline-video",
          text: trans["grid.widgets.units.subsystems_tree.video.timeline"],
          icon: `feather icon-clock`,
          data: cam,
          unit_data,
          visible: validatePermissions('Video:record_video', unit_data),
        });

        children.push({
          id: `cam_${cam.id}_info`,
          type: "action",
          tag: "info",
          text: trans["grid.widgets.units.subsystems_tree.info"],
          icon: "feather icon-info",
          data: cam,
          unit_data,
          visible: true,
        });

        if (
          cam.camera_type === "camera_manager" &&
          (user.value.is_master_admin || user.value.is_super_admin)
        ) {
          children.push({
            id: `cam_${cam.id}_activate_do`,
            type: "action",
            tag: "run-cmd-activate-cam-do",
            text: trans["grid.widgets.units.subsystems_tree.activate_digital_output"],
            icon: "feather icon-play-circle",
            data: cam,
            unit_data,
            visible: true,
          });

          children.push({
            id: `cam_${cam.id}_deactivate_do`,
            type: "action",
            tag: "run-cmd-deactivate-cam-do",
            text: trans["grid.widgets.units.subsystems_tree.disable_digital_output"],
            icon: "feather icon-pause-circle",
            data: cam,
            unit_data,
            visible: true,
          });
        }

        item.addChild({
          id: cam.id,
          // type: "action",
          tag: "camera-name",
          text: cam.name || cam.camera_name,
          icon: `feather ${resolveCameraStatus(cam)}`,
          data: cam,
          unit_data,
          children,
        });
      });
    }
  };

  const validatePermissions = (permission, unitData = null) => {
    const [subject, action] = permission.split(":");

    if (!unitData) {
      return ability.can(action, subject);
    }

    if (unitData.shared_tenant_permissions.length) {
      return unitData.shared_tenant_permissions.includes(`${subject}:${action}`);
    }

    return ability.can(action, subject);
  };

  const resolveCameraStatus = (cam) => {
    if (["bridge"].includes(cam.camera_type)) return "icon-circle text-success";

    if (cam.camera_type === "camera_manager") {
      return cam.online !== 1
        ? "icon-alert-triangle text-danger"
        : "icon-circle text-success";
    }

    return cam.camera_proxy_status !== 1
      ? "icon-alert-triangle text-danger"
      : "icon-circle text-success";
  };

  const resolveCameraServiceStatus = (cam, service) => {
    if (["bridge", "generic_device"].includes(cam.camera_type))
      return service === "live_video" ? "icon-play-circle" : "icon-film";

    if (cam.camera_type === "camera_manager") {
      return cam.online !== 1
        ? "icon-wifi-off text-danger"
        : service === "live_video"
          ? "icon-play-circle"
          : "icon-film";
    }

    return cam.camera_proxy_status !== 1
      ? "icon-wifi-off text-danger"
      : service === "live_video"
        ? "icon-play-circle"
        : "icon-film";
  };

  // node actions
  const actions = async (item, vm, isDnd = false) => {
    if (item.data === "offline") return;
    switch (item.tag) {
      // Main unit actions
      case "unit-info":
        setSlotData(
          SLOT_TYPES.INFO,
          item.unit_data.description,
          { url: item.unit_data.info_url },
          SOURCES.SUBSYSTEM_TREE
        );
        break;

      case "unit-map":
        setSlotData(
          SLOT_TYPES.UNITS_MAP,
          `Mapa de la instalación: ${item.unit_data.description}`,
          { unit_id: item.unit_data.id },
          SOURCES.SUBSYSTEM_TREE
        );
        break;

      case "unit-plane":
        setSlotData(
          SLOT_TYPES.PLANE,
          `Planos: ${item.unit_data.description}`,
          { planes_url: item.unit_data.unit_planes },
          SOURCES.SUBSYSTEM_TREE
        );
        break;

      case "digital-output":
        if (isDnd) return;
        await store.dispatch("subsystem_tree/showSubsystemTreeModal", {
          data: {
            digital_output_link: item.digital_output_link,
          },
          source: SOURCES.SUBSYSTEM_TREE_DO,
        });
        vm.$root.$emit("bv::show::modal", "subsystem_action_modal");
        break;

      case "virtual-alarm":
        if (isDnd) return;
        await store.dispatch("subsystem_tree/showSubsystemTreeModal", {
          data: item.data,
          source: SOURCES.SUBSYSTEM_TREE_VA,
        });
        vm.$root.$emit("bv::show::modal", "subsystem_action_modal");
        break;

      // Video actions...
      case "camera-name":
        setSlotData(
          SLOT_TYPES.CAM_LIVE,
          item.data.name || `CAM_${item.data.camera_id}`,
          { camera: item.data, region: item.unit_data.region },
          SOURCES.SUBSYSTEM_TREE
        );
        break;

      case "live-video":
        setSlotData(
          SLOT_TYPES.CAM_LIVE,
          item.data.name || `CAM_${item.data.camera_id}`,
          { camera: item.data, region: item.unit_data.region },
          SOURCES.SUBSYSTEM_TREE
        );
        break;

      case "recorded-video":
        setSlotData(
          SLOT_TYPES.CAM_RECORDED,
          item.data.name || `CAM_${item.data.camera_id}`,
          { camera: item.data, region: item.unit_data.region },
          SOURCES.SUBSYSTEM_TREE
        );
        break;

      case "info":
        setSlotData(
          SLOT_TYPES.INFO,
          `Cámara: ${item.data.name}`,
          { camera: item.data },
          SOURCES.CAMERA
        );
        break;

      case "run-cmd-activate-cam-do":
        if (isDnd) return;
        if (confirm("¿Segur@ de activar la salida digital de la cámara?")) {
          const camera = item.data;
          try {
            await axios.get("/v1/command/run-command-by-name", {
              params: { name: "activate-cam-do", id: camera.camera_id },
            });
            alert("Commando ejecutado");
          } catch (error) {
            console.log("[run command from subsystem tree]", error);
            alert("Ocurrió un error al ejecutar el comando");
          }
        }
        break;

      case "run-cmd-deactivate-cam-do":
        if (isDnd) return;
        if (confirm("¿Segur@ de desactivar la salida digital de la cámara?")) {
          const camera = item.data;
          try {
            await axios.get("/v1/command/run-command-by-name", {
              params: { name: "deactivate-cam-do", id: camera.camera_id },
            });
            alert("Commando ejecutado");
          } catch (error) {
            console.log("[run command from subsystem tree]", error);
            alert("Ocurrió un error al ejecutar el comando");
          }
        }
        break;

      case "statistics":
        if (isDnd) {
          setSlotData(
            "grid/setStaticsView",
            `Statics ${item.unit_data.description}`,
            item.unit_data,
            SOURCES.SUBSYSTEM_TREE_DND
          );
        } else {
          await store.dispatch("grid/setStaticsView", {
            data: item.unit_data,
            source: SOURCES.SUBSYSTEM_TREE_VA,
          });
        }
        break;

      case "unit-live":
        if (isDnd) {
          setSlotData(
            "grid/setUnitLiveVideo",
            `Live ${item.unit_data.description}`,
            {
              data: item.unit_data,
              cameras: item.cameras,
            },
            SOURCES.SUBSYSTEM_TREE_DND
          )
        } else {
          await store.dispatch("grid/setUnitLiveVideo", {
            data: item.unit_data,
            cameras: item.cameras,
            source: SOURCES.SUBSYSTEM_TREE_VA,
          });
        }
        break;

      case "unit-recording":
        if (isDnd) {
          setSlotData(
            "grid/setUnitRecordingVideo",
            `Recording ${item.unit_data.description}`,
            {
              data: item.unit_data,
              cameras: item.cameras,
            },
            SOURCES.SUBSYSTEM_TREE_DND
          )
        } else {
        await store.dispatch("grid/setUnitRecordingVideo", {
          data: item.unit_data,
          cameras: item.cameras,
          source: SOURCES.SUBSYSTEM_TREE_VA,
        });
        }
        break;

      case "timeline-video":
        if (isDnd) {
          setSlotData(
            "grid/setTimelineView",
            `Timeline ${item.data.name}`,
            item.data,
            SOURCES.SUBSYSTEM_TREE_DND
          );
        } else {
          await store.dispatch("grid/setTimelineView", {
            data: item.data,
            source: SOURCES.SUBSYSTEM_TREE,
          });
        }
        break;

    }
  };

  return { setChildrenData, actions };
}
