<template>
  <div>
    <tree
      ref="tree"
      :data="data"
      :mode="mode"
      :role="role"
      @item-click="onClickNode"
      @item-drag-start="onDragStart"
      @item-drag-end="onDragEnd"
    />
  </div>
</template>

<script>
import Tree from "@/components/Tree";
import { debounce } from "lodash";
import useVideo from "@/views/components/GridView/components/SubsystemTree/composables/video";
import useVoIP from "@/views/components/GridView/components/SubsystemTree/composables/voIP";
import { getCurrentInstance, ref, watch, computed } from "@vue/composition-api";
import { useMagicKeys } from "@vueuse/core";

export default {
  components: {
    Tree,
  },
  props: {
    unit: Object,
    mode: {
      type: String,
      default: "widget",
    },
    role: {
      type: String,
      default: "disabled",
    },
    data: {
      type: Array,
    },
  },

  setup(props) {
    const video = useVideo(props.unit);
    const voIP = useVoIP();
    const vm = getCurrentInstance().proxy;
    const editingNode = ref(null);

    const { Delete } = useMagicKeys();

    watch(Delete, (pressed) => {
      if (pressed) {
        deleteNode();
      }
    });

    const onToggleItem = debounce(function (_, item) {
      if (item.tag === "cctv") {
        if (item.children[0].type === "parent") {
          item.children = [];
          video.setChildrenData(item);
        }
      }

      if (item.tag === "voip") {
        if (item.children[0].type === "parent") {
          item.children = [];
          voIP.setChildrenData(item);
        }
      }
    });

    const onClickNode = debounce(async function (node, item) {
      if (this.mode === "editor") {
        editingNode.value = node;
      } else {
        item.selected = false;
        if (item.type === "action") {
          video.actions(item, vm);
          voIP.actions(item, vm);
        }
      }
    }, 200);

    const deleteNode = () => {
      if (editingNode.value && editingNode.value.data.id !== 0) {
        let index = editingNode.value.parentItem.indexOf(editingNode.value.data);
        if (index > -1) {
          editingNode.value.parentItem.splice(index, 1);
          editingNode.value = null;
        }
      }
    };

    const onDragStart = (item) => {
      video.actions(item, vm, true);
      voIP.actions(item, vm, true);
    };

    const onDragEnd = () => {};

    return {
      onToggleItem,
      onClickNode,
      onDragStart,
      onDragEnd,
      deleteNode,
    };
  },
};
</script>

<style lang="scss">
.tree {
  .tree-anchor {
    i.no-icon {
      display: none;
    }
  }
}
</style>
