
import { SOURCES } from "@/config/layoutConfig";
import store from "@/store";

export default function useLayoutSlots() {

  const setSlotData = (type, name, data, source = null) => {
    store.dispatch("grid/setSlotData", {
      type,
      name,
      data: {
        ...removeReactivity(data),
        in_app_source: source,
      },
      source: source ? source : SOURCES.ALARM
    });
  }

  const buildSlot = (name, position, data, type) => {
    return {
      name,
      pos: position,
      data,
      type
    }
  }

  const placeSlots = (slots) => {
    store.commit("grid/forceLayoutSlots", slots);
  }

  const removeReactivity = (data) => {
    return JSON.parse(JSON.stringify(data));
  }

  return { setSlotData, buildSlot, placeSlots, removeReactivity };
}
