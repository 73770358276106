<template>
  <div>
    <b-table
      class="subsystem-tree__table mb-0"
      :items="items"
      :fields="fields"
      :busy="isBusy"
      show-empty
      :empty-text="$t('grid.widgets.units.messages.no_data')"
      responsive
      small
    >
      <template #head(description)="data">
        <span> {{ $t(data.label) }}</span>
      </template>
      <template #cell(description)="row">
        <keep-alive>
          <subsystem-tree
            class="subsystem-tree__data"
            :key="row.item.id"
            :unit="row.item"
            :mode="TreeMode"
            :role="role"
            @toggle-item="onToggleItem"
          />
        </keep-alive>
      </template>
    </b-table>

    <div class="d-flex justify-content-end align-content-center">
      <div class="d-flex align-items-center mr-1 pr-1 border-right">
        <span>{{ $t("grid.widgets.units.pagination.showing") }}</span>
        <select class="mx-50" @input="onChangeRecordesPerPage">
          <option v-for="rpp in recordsPerPage" :key="rpp" :value="rpp">
            {{ rpp }}
          </option>
        </select>
        <span>{{ $t("grid.widgets.units.pagination.records") }}</span>
      </div>

      <div
        class="pagination-count d-flex align-items-center mr-25"
        v-if="!sidebarMinWidthReached"
      >
        {{ $t("grid.widgets.units.pagination.showing") }} {{ items.length }}
        {{ $t("grid.widgets.units.pagination.of") }} {{ getTotalUnits }} ({{
          $t("grid.widgets.units.pagination.page")
        }}
        {{ currentPage }} {{ $t("grid.widgets.units.pagination.of") }} {{ totalPages }})
      </div>
      <div class="pagination d-flex justify-content-end">
        <a
          href="#"
          :class="[
            'btn btn-sm btn-secondary px-50 rounded-0',
            { disabled: currentPage === 1 },
          ]"
          @click="clickPaginationBtn('prev')"
        >
          <feather-icon icon="ChevronLeftIcon" size="16" />
        </a>
        <a
          href="#"
          :class="[
            'btn btn-sm btn-secondary px-50 rounded-0',
            { disabled: totalPages === currentPage },
          ]"
          @click="clickPaginationBtn('next')"
        >
          <feather-icon icon="ChevronRightIcon" size="16" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {
  BTable,
  BPagination,
  BModal,
  BTooltip,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import SubsystemTree from "../components/SubsystemTree";

export default {
  props: {
    idTenant: String | Number,
    totalUnits: Number,
    filter: {
      type: Array,
      default: []
    },
    TreeMode: {
      type: String,
      default: "widget",
    },
    role: {
      type: String,
      default: "disabled",
    },
    parentOpened: {
      type: Boolean,
    },
  },
  components: {
    BTable,
    BPagination,
    BModal,
    BTooltip,
    BDropdown,
    BDropdownItem,
    SubsystemTree,
  },
  data() {
    return {
      fields: [
        {
          key: "description",
          label: "grid.widgets.units.table.rows.1",
        },
      ],
      recordsPerPage: [5, 10, 25, 50, 100],
      idUnit: null,
      perPage: 5,
      currentPage: 1,
      isBusy: false,
      pagination: {
        first: null,
        last: null,
        next: null,
        prev: null,
      },
    };
  },
  computed: {
    getTotalUnits() {
      let total = store.getters["grid/getTotalUnits"]({
        idUnit: this.idUnit,
        idTenant: this.idTenant,
        filter: this.filter,
      });
      this.$emit("update:totalUnits", total);
      return total;
    },
    items() {
      return store.getters["grid/getUnits"]({
        idUnit: this.idUnit,
        idTenant: this.idTenant,
        filter: this.filter,
        currentPage: this.currentPage,
        perPage: this.perPage
      })
    },
    totalPages() {
      return Math.ceil(this.getTotalUnits / this.perPage);
    },
    sidebarMinWidthReached() {
      return store.getters["grid/sidebarMinWidthReached"];
    },
    treeConfig() {
      const config = store.getters["grid/getTreeById"](this.idTenant);
      return config
        ? config.children.reduce((acc, item) => {
            acc[item.unitId] = item.open;
            return acc;
          }, {})
        : null;
    },
  },
  methods: {
    clickPaginationBtn(direction) {
      if (direction === "prev") {
        if (this.currentPage - 1 > 0) {
          this.currentPage--;
        }
      } else {
        if (this.currentPage + 1 <= this.totalPages) {
          this.currentPage++;
        }
      }
    },
    onUnitClick({ item }) {
      const selectedUnit = item;

      this.$http.get(selectedUnit.cameras_link).then((resp) => {
        store.dispatch("grid/showLayoutModal", {
          source: "unit",
          data: {
            obj: selectedUnit,
            cameras: resp.data.data,
          },
        });
      });

      this.$root.$emit("bv::show::modal", "layout_modal");
    },
    onToggleItem(data) {
      const { item, unit } = data;

      // TODO: Improve this code
      if (item.children.length === 5) {
        const payload = {
          tenantId: this.idTenant,
          unitId: unit.id,
          open: item.opened,
          entity: "unit",
        };
        store.dispatch("grid/updateTree", payload);
      }
    },
    resetDataModal() {
      this.dataModal.data = {};
    },
    onChangeRecordesPerPage(val) {
      this.perPage = val.target.value;
    },
  },
};
</script>
