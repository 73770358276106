<template>
  <div>
    <tree
      ref="tree"
      :data="unit.subsystem_tree"
      @item-click="onClickNode"
      @item-toggle="onToggleItem"
      @item-drag-start="onDragStart"
      @item-drag-end="onDragEnd"
      :cbk="addItemsWhenDragStart"
      :mode="mode"
      :role="role"
    />
  </div>
</template>

<script>
import Tree from "@/components/Tree";
import { debounce } from "lodash";
import useVideo from "./composables/video";
import useVoIP from "./composables/voIP";
import useActuators from "./composables/actuators";
import useGenericDevices from "./composables/genericDevices";
import useSensors from "./composables/sensors";
import { getCurrentInstance } from "@vue/composition-api";
import store from "@/store";

export default {
  components: {
    Tree,
  },
  props: {
    unit: Object,
    mode: {
      type: String,
      default: "widget",
    },
    role: {
      type: String,
      default: "disabled",
    },
  },

  setup(props, { emit }) {
    const video = useVideo(props.unit);
    const voip = useVoIP();
    const actuators = useActuators();
    const generic_devices = useGenericDevices();
    const sensors = useSensors();
    const vm = getCurrentInstance().proxy;

    const tags = {
      video, // video
      voip,
      actuators,
      generic_devices,
      sensors,
    };

    const onToggleItem = debounce(function (_, item) {
      emit("toggle-item", { item, unit: props.unit });

      for (const _ in tags) {
        if (item.children[0].type === "parent") {
          item.children = [];
          if (tags[item.tag].hasOwnProperty("setChildrenData")) {
            tags[item.tag].setChildrenData(item);
          }
        }
      }
    });

    const onClickNode = debounce(async function (_, item) {
      item.selected = false;
      if (item.type === "action") {
        for (const tag in tags) {
          tags[tag].actions(item, vm);
        }
      }
    }, 200);

    const onDragStart = (item) => {
      for (const tag in tags) {
        tags[tag].actions(item, vm, true);
      }
    };

    // Only needed in custom groups
    const addItemsWhenDragStart = (item) => {
      if (item.tag === "parent" && item.children.length) {
        item.children.forEach((itm) => {
          for (const _ in tags) {
            if (itm.children[0] && itm.children[0].type === "parent") {
              itm.children = [];
              if (tags[itm.tag].hasOwnProperty("setChildrenData")) {
                tags[itm.tag].setChildrenData(itm);
              }
            }
          }
        });
      } else {
        for (const _ in tags) {
          if (item.children[0] && item.children[0].type === "parent") {
            item.children = [];
            if (tags[item.tag].hasOwnProperty("setChildrenData")) {
              tags[item.tag].setChildrenData(item);
            }
          }
        }
      }

      // return item;
    };

    const onDragEnd = () => {
      store.dispatch("grid/resetSlotSelection");
    };

    return {
      onToggleItem,
      onClickNode,
      onDragStart,
      onDragEnd,
      addItemsWhenDragStart,
    };
  },
};
</script>

<style lang="scss">
.tree {
  .tree-anchor {
    i.no-icon {
      display: none;
    }
  }
}
</style>
