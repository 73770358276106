import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import useLayoutSlots from '@/composables/layoutSlots';
import store from "@/store";
import { EventBus } from "@/libs/event-bus";
import axios from '@axios';

export default function useSensors() {

  const { setSlotData, removeReactivity } = useLayoutSlots();
  const { t } = useI18nUtils();

  const setChildrenData = (item, actions) => {

    const devices = item.data;

    if (devices.length > 0) {

      devices.forEach((device) => {
        let child_device;

        const rootData = device.subsystem_tree_data.root_data;
        const children = [];
        device.subsystem_tree_data.actions.forEach(item => children.push(item));

        child_device = {
          id: device.device_id + "_root",
          text: rootData.text,
          icon: rootData.icon,
          tag: "sensors",
          visible: true,
          children,
        };

        item.addChild(child_device);
      });
    }
  }

  const actions = async (item, vm, isDnd = false) => {

    switch (item.tag) {

      case "info-sensor":
        setSlotData(
          SLOT_TYPES.INFO,
          `${item.data.name} (${item.data.camera_proxy_id})`,
          {
            id: item.data.camera_proxy_id,
            sensor_type: item.data.camera_type,
            id_sensor: item.data.sensor_id,
          },
          SOURCES.SUBSYSTEM_TREE_SENSOR
        );
        break;
    };
  };

  return { setChildrenData, actions };
}
