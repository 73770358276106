import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import useLayoutSlots from '@/composables/layoutSlots';
import store from "@/store";
import { EventBus } from "@/libs/event-bus";

export default function useVoIP() {

  const { setSlotData, removeReactivity } = useLayoutSlots();
  const { t } = useI18nUtils();
  const trans = {
    'grid.widgets.units.subsystems_tree.voip.make_call': t('grid.widgets.units.subsystems_tree.voip.make_call'),
    'grid.widgets.units.subsystems_tree.info': t('grid.widgets.units.subsystems_tree.info'),
  };

  const setChildrenData = (item) => {

    const devices = item.data;

    if (devices.length > 0) {

      devices.forEach((device) => {
        let child_device;

        if (device.registered) {
          child_device = {
            id: device.device_id + "_padre",
            text: `${device.device_id} - ${device.type_device_interphone.type}`,
            icon: "feather icon-phone text-success",
            tag: "voip",
            visible: true,
            children: [
              {
                text: trans["grid.widgets.units.subsystems_tree.voip.make_call"],
                icon: "feather icon-phone-outgoing text-success",
                type: "action",
                tag: "call",
                id: device.device_id,
                visible: true,
              },
              {
                type: "action",
                tag: "info-interphone",
                text: trans["grid.widgets.units.subsystems_tree.info"],
                icon: "feather icon-info",
                data: device,
                visible: true,
              },
            ],
          };
        } else {
          child_device = {
            id: device.device_id + "_padre",
            text: `${device.device_id} - ${device.type_device_interphone.type}`,
            icon: "feather icon-phone-off text-danger",
            tag: "voip",
            visible: true,
            children: [
              {
                type: "action",
                tag: "info-interphone",
                text: trans["grid.widgets.units.subsystems_tree.info"],
                icon: "feather icon-info",
                data: device,
                visible: true,
              },
            ],
          };
        }

        item.addChild(child_device);
      });
    }
  }

  const actions = (item, vm, isDnd = false) => {

    switch (item.tag) {

      case "call":
        if (isDnd) return;
        EventBus.emit(`call_device`, item);
        break;

      case "info-interphone":
        setSlotData(
          SLOT_TYPES.INTERPHONE,
          item.data.device_id,
          { data: removeReactivity(item.data) },
          SOURCES.SUBSYSTEM_TREE
        );
        break;

    };
  };

  return { setChildrenData, actions };
}
