import { useToast } from "vue-toastification/composition";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import axios from "@axios";
import {
  computed,
  onMounted,
  ref,
  watch,
  reactive,
} from "@vue/composition-api";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { v4 as uuidv4 } from "uuid";

export default function useUnitGroupsConfig() {

  const isUpdating = ref(false);
  const unitGroupName = ref("");
  const isDragEnter = ref(false);
  const idUnitGroup = ref(null);

  const toast = useToast();
  const { t } = useI18nUtils();
  const trans = {
    'unit_groups.messages.success_created': t('unit_groups.messages.success_created'),
    'unit_groups.messages.success_updated': t('unit_groups.messages.success_updated'),
  };

  const { refFormObserver, getValidationState } = formValidation();
  const unitGroupData = ref(null);
  const unitGroupDefaultData = [
    {
      id: 0,
      text: t('unit_groups.parent_name'),
      value: 'Grupos',
      icon: 'feather icon-box',
      opened: false,
      selected: false,
      disabled: false,
      loading: false,
    }
  ];
  const groupName = ref("");

  const addNewGroup = () => {
    if (groupName.value === '') return;

    unitGroupData.value[0].addChild({
      text: groupName.value,
      value: groupName.value,
      icon: "feather icon-box",
      opened: false,
      selected: false,
      disabled: false,
      loading: false,
    });

    groupName.value = "";
  };

  // Methods ------------------------------------

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    isDragEnter.value = false;
    console.log(e);
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    isDragEnter.value = true;
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    isDragEnter.value = false;
  };

  const loadInitData = async () => {
  };

  const loadUpdateData = async (idUnitGroup) => {
    const { data } = await axios.get(`v1/unit-groups/${idUnitGroup}`);
    const response = data.data;
    unitGroupName.value = response.name;
    unitGroupData.value = response.data ?
      response.data :
      unitGroupDefaultData;

  };

  const save = async () => {
    const validation = await refFormObserver.value.validate();

    if (validation) {

      const data = {
        name: unitGroupName.value,
        data: unitGroupData.value
      };

      if (!isUpdating.value) {

        axios.post("v1/unit-groups", data).then(({ data }) => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans['unit_groups.messages.success_created'],
              icon: "CheckIcon",
              variant: "success",
            },
          });

          isUpdating.value = true;
          idUnitGroup.value = data.data.id;
          router.push({ path: `/unit-groups/config/${idUnitGroup.value}` });
        });

      } else {

        axios.put(`v1/unit-groups/${idUnitGroup.value}`, data).then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans['unit_groups.messages.success_updated'],
              icon: "CheckIcon",
              variant: "success",
            },
          });
        });

      }

    }
  };


  // Hooks ------------------------------------
  onMounted(async () => {

    await loadInitData();

    if (router.currentRoute.params.id_units_group) {
      isUpdating.value = true;
      idUnitGroup.value = router.currentRoute.params.id_units_group;
      loadUpdateData(idUnitGroup.value);
    } else {
      unitGroupData.value = unitGroupDefaultData;
    }

  });

  return {
    isUpdating,
    unitGroupName,

    refFormObserver,
    getValidationState,
    required,
    save,

    onDragOver,
    onDrop,
    onDragEnter,
    onDragLeave,

    isDragEnter,
    unitGroupData,
    groupName,
    addNewGroup,
  };
}
